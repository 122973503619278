import React, { createContext, useContext, useMemo } from 'react'

export type CompanyContextType = {
  company: Company
}

const CompanyContext = createContext<CompanyContextType>(null)

const CompanyProvider = ({ company, children }) => {
  const value = useMemo(() => ({ company }), [company])

  return <CompanyContext.Provider value={value}>{children}</CompanyContext.Provider>
}

const useCompanyContext = () => useContext(CompanyContext)

export { useCompanyContext, CompanyContext, CompanyProvider }
