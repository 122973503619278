// Get All Brands
import { useQuery } from '@tanstack/react-query'
import { getAllLocations, getDatasourceById } from 'src/api/microservices/tenants'
import usePaginatedQuery from 'src/utils/microservice/paginated-query'

export const useMicroserviceDatasource = ({ datasource_id }) =>
  useQuery({
    queryKey: ['datasource-by-id', { datasource_id }],
    queryFn: async () => {
      let [err, response] = await getDatasourceById({ datasource_id })

      if (err) throw err
      return response.data.data
    },
    gcTime: 0,
  })

export const useGetAllLocations = ({ tenantId, search, locationType = null }) =>
  usePaginatedQuery({
    queryKey: ['get-all-locations', { tenantId, search, locationType }],
    queryFn: async ({ continuationToken, pageSize }) => {
      let [err, response] = await getAllLocations({
        tenantId,
        search,
        locationType,
        continuationToken,
        pageSize,
      })

      if (err) throw err
      return response
    },
  })
