import React from 'react'
import { Field } from 'formik'
import {
  Select as FormikSelect,
  Switch as FormikSwitch,
  TextField as FormikTextField,
} from './index'
import { Box, HStack } from '@chakra-ui/react'

export function FormRow({ children }) {
  return (
    <HStack align="center" spacing={4} m={4} px={6}>
      {children}
    </HStack>
  )
}

export function TextField({ readOnly = false, ...props }) {
  return (
    <Box flex={1}>
      <Field
        component={FormikTextField}
        formControlProps={{ height: '62px', mb: null }}
        InputProps={{ isDisabled: readOnly }}
        {...props}
      />
    </Box>
  )
}

export function SelectField({ options, readOnly = false, ...props }) {
  return (
    <Box flex={1}>
      <Field
        component={FormikSelect}
        formControlProps={{ height: '62px', mb: null }}
        options={options}
        selectProps={{ sortOptions: false, isDisabled: readOnly }}
        {...props}
      />
    </Box>
  )
}

export function MultiSelectField({ options, ...props }) {
  return (
    <Box flex={1}>
      <Field
        component={FormikSelect}
        formControlProps={{ height: 'auto', mb: null }}
        options={options}
        selectProps={{
          isMulti: true,
          sortOptions: false,
          styles: {
            control: {
              height: 'auto',
            },
          },
        }}
        {...props}
      />
    </Box>
  )
}

export function SwitchField(props) {
  return (
    <Box flex={1}>
      <Field
        component={FormikSwitch}
        formControlProps={{
          flexDirection: 'column',
          alignItems: 'flex-start',
          height: '62px',
          mb: null,
        }}
        SwitchBoxProps={{ height: '32px' }}
        {...props}
      />
    </Box>
  )
}
