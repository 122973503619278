import { microservicesClient } from 'src/utils/api'
import { ICompany } from 'src/api/api'
import { tryQuery } from 'src/utils/queries'

const baseUri = '/tenant'

export interface GetDatasourceByIdRequest {
  datasource_id: ICompany['datasource_id']
}

export const getDatasourceById = async ({ datasource_id }: GetDatasourceByIdRequest) => {
  try {
    let response = await microservicesClient.post(`${baseUri}/v1/GetDatasourceById`, {
      id: datasource_id,
    })
    return [null, response]
  } catch (err) {
    return [err, null]
  }
}

type GetAllLocationsRequest = {
  tenantId: string
  search?: string
  locationType?: string
  continuationToken?: string
  pageSize?: number
}
export const getAllLocations = async ({
  tenantId,
  search,
  locationType,
  continuationToken,
  pageSize,
}: GetAllLocationsRequest) => {
  return tryQuery(
    microservicesClient.post(
      `/location/v1/GetAllLocations`,
      {
        name: search,
        locationType,
        pagination: {
          pageSize,
          token: continuationToken,
          sortPlan: [
            {
              column: 'internalName',
              direction: 'asc',
            },
            {
              column: 'publicName',
              direction: 'asc',
            },
            {
              column: 'sourceName',
              direction: 'asc',
            },
            {
              column: 'id',
              direction: 'asc',
            },
          ],
        },
      },
      {
        headers: {
          'X-Bushel-Tenant': tenantId,
        },
      }
    )
  )
}
