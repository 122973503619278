import React, { createContext, useContext, useMemo, useState } from 'react'
import { LocationType } from './utils'

type LocationContextType = {
  locationId: string
  company: Company
  location: TenantLocation
  parent: TenantLocation
  setParent: (location: TenantLocation) => void
  facilityChildren: TenantLocation[]
  setFacilityChildren: (newChildren: TenantLocation[]) => void
  locationType: string
  setLocationType: (type: string) => void
  isError: boolean
}

const LocationDetailContext = createContext<LocationContextType>(null)

const LocationDetailProvider = ({ locationData, isError, company, locationId, children }) => {
  const location = locationData?.data
  const [parent, setParent] = useState(() => location?.parent)
  const [facilityChildren, setFacilityChildren] = useState(location?.children ?? [])
  const [locationType, setLocationType] = useState(locationData?.type ?? LocationType.location)

  const valueMemo = useMemo(
    () => ({
      locationId,
      company,
      location,
      parent,
      setParent,
      facilityChildren,
      setFacilityChildren,
      locationType,
      setLocationType,
      isError,
    }),
    [
      locationId,
      company,
      location,
      parent,
      setParent,
      facilityChildren,
      setFacilityChildren,
      locationType,
      setLocationType,
      isError,
    ]
  )

  return (
    <LocationDetailContext.Provider value={valueMemo}>{children}</LocationDetailContext.Provider>
  )
}

const useLocationDetailContext = () => useContext(LocationDetailContext)
export { useLocationDetailContext, LocationDetailContext, LocationDetailProvider }
