import React from 'react'
import { AuthRoute } from 'src/routes'
import LocationsPage from './v2'
import CompanyLocations from 'src/companies/locations'
import { IS_DEV_OR_SANDBOX, IS_PROD } from 'src/utils/config'
import { getEnvironment } from 'src/utils'

export default function LocationsRouter({ company, companies, setCopyDialog }) {
  /**
   * For Dev, Sandbox, or Production environments, we want to use the new location management which
   * uses the tenant service locations instead of centre (data is still synced to both). For UAT,
   * we still need to use the old layout as the microservices do not have a UAT environment.
   */
  if (IS_DEV_OR_SANDBOX || (IS_PROD && getEnvironment() === 'production')) {
    return <AuthRoute as={LocationsPage} />
  }

  return (
    <AuthRoute
      as={CompanyLocations}
      company={company}
      companies={companies}
      setCopyDialog={setCopyDialog}
    />
  )
}
