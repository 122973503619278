import {
  Badge,
  Box,
  Center,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  VStack,
} from '@chakra-ui/react'
import InfiniteScroll from 'react-infinite-scroll-component'
import Table2, { useTable } from 'src/ui/table2'
import { Button, Row } from 'src/ui'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocationDetailContext } from 'src/companies/locations/v2/location-details-context'
import { useDebounce } from 'src/utils'
import { useGetAllLocations } from 'src/api/queries/microservices/tenants'
import { LocationType } from 'src/companies/locations/v2/utils'
import SearchInput from 'src/ui/search-input'

type InfiniteModalProps = {
  isOpen: boolean
  onClose: () => void
  handleAddClicked: (location: TenantLocation) => void
  locationType: string
}

export default function InfiniteModal({
  isOpen,
  onClose,
  handleAddClicked,
  locationType,
}: Readonly<InfiniteModalProps>) {
  const scrollContainer = 'infinite_scroll_container'
  const { company, locationId, parent, facilityChildren } = useLocationDetailContext()
  const [search, setSearch] = useState('')
  const throttledValue = useDebounce(search, 500)

  const { data, fetchNextPage, hasNextPage, isLoading } = useGetAllLocations({
    tenantId: company.tenant_id,
    search: throttledValue,
    locationType: locationType === LocationType.facility ? 'Location' : 'Facility', // If facility modal, we want locations and vice versa
  })

  const filteredData = useMemo(
    () =>
      data?.filter(
        (location) =>
          location.id !== locationId && // Do not include the location we are editing in the list
          (!parent || parent.id !== location.id) && // Do not include the parent, this should never be relevant as you can't open the modal if a parent already exists, but adding as a failsafe
          !facilityChildren?.some((fc) => fc.id === location.id) // Do not show any already added children in the list
      ),
    [data, locationId, facilityChildren, parent]
  )

  useEffect(() => {
    setSearch('')
  }, [isOpen])

  const handleAdd = useCallback(
    (location: TenantLocation) => {
      handleAddClicked(location)
      onClose()
    },
    [handleAddClicked, onClose]
  )

  const columns = useMemo(
    () => [
      {
        id: 'sourceName',
        accessor: 'sourceName',
        Header: 'Name',
        disableSortBy: true,
      },
      {
        id: 'sourceId',
        accessor: 'sourceId',
        Header: 'Remote ID',
        disableSortBy: true,
      },
      {
        id: '_actions',
        accessor: '_actions',
        Header: '',
        disableSortBy: true,
        Cell: ({ row }) => (
          <Row justifyContent="flex-end">
            {row.original.parent && row.original.parent.id !== locationId ? (
              <Badge backgroundColor="gray.200" color="gray.500" size="xs">
                Linked to another facility
              </Badge>
            ) : (
              <Button size="sm" colorScheme="primary" onClick={() => handleAdd(row.original)}>
                Add
              </Button>
            )}
          </Row>
        ),
      },
    ],
    [locationId, handleAdd]
  )

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable({
    data: filteredData,
    tableOptions: {
      initialState: {
        sortBy: [],
      },
    },
    columns,
  })

  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {locationType === LocationType.facility ? 'Add Sub-Locations' : 'Add Parent Location'}
        </ModalHeader>
        <ModalBody>
          <VStack spacing={4} alignItems="start">
            <SearchInput searchValue={search} setSearchValue={setSearch} isLoading={isLoading} />
            <Box id={scrollContainer} height="300px" overflowY="auto" width="100%">
              <Box bg="white" mb={4} pt={3} borderRadius={3} boxShadow="md">
                <InfiniteScroll
                  next={() => fetchNextPage()}
                  hasMore={hasNextPage}
                  loader={
                    <Center h="50px">
                      <Spinner speed="0.8s" thickness="3px" color="inherit" />
                    </Center>
                  }
                  dataLength={data?.length ?? 0}
                  scrollableTarget={scrollContainer}
                >
                  <Table2 {...getTableProps} striped>
                    <Table2.Header headerGroups={headerGroups} />
                    <Table2.Body
                      rows={rows}
                      getTableBodyProps={getTableBodyProps}
                      prepareRow={prepareRow}
                    />
                  </Table2>
                </InfiniteScroll>
              </Box>
            </Box>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" colorScheme="primary" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
