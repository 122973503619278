import { bidlClient } from 'src/utils/api'
import { tryQuery } from 'src/utils/queries'

const baseUri = '/api/aggregator'

export type CreateOrUpdateLocationRequest = {
  data: {
    companyId: number
    datasourceId: string
    sourceId: string
    sourceName?: string
    internalName?: string
    publicName?: string
    parentId?: string
    physicalAddressLine1?: string
    physicalAddressLine2?: string
    physicalLocality?: string
    physicalAdministrativeArea?: string
    physicalPostalCode?: string
    physicalCountryCode?: string
    latitude?: number
    longitude?: number
    integrationId?: string
    businessType?: BusinessType[]
    businessEmail?: string
    businessWebsite?: string
    businessFax?: string
    businessPhone?: string
    pushNotificationsHiddenAt?: string
    cashBidsHiddenAt?: string
    hidden?: boolean
    revision?: string
  }
  type: TenantLocationType
}

export const FindOrCreateTenantBySlug = async (slug) => {
  try {
    const response = await bidlClient.post(
      `${baseUri}/config/v1/FindOrCreateTenantBySlug`,
      {},
      {
        headers: {
          'App-Company': slug,
        },
      }
    )
    return [null, response.data]
  } catch (err) {
    return [err, null]
  }
}

export const GetConfigurables = async (slug) => {
  try {
    const response = await bidlClient.post(
      `${baseUri}/config/v1/GetConfigurables`,
      {},
      {
        headers: {
          'App-Company': slug,
        },
      }
    )
    return [null, response.data]
  } catch (err) {
    return [err, null]
  }
}

export const UpdateConfigurables = async (config, slug) => {
  try {
    const response = await bidlClient.post(
      `${baseUri}/config/v1/UpdateTenantConfig`,
      JSON.stringify(config),
      {
        headers: {
          'App-Company': slug,
          'Content-Type': 'application/json',
        },
      }
    )
    return [null, response.data]
  } catch (err) {
    return [err, null]
  }
}

type GetLocationByIdRequest = {
  tenantId: string
  locationId: string
  companyId: number
}

export const GetLocationById = async ({
  tenantId,
  locationId,
  companyId,
}: GetLocationByIdRequest) => {
  return tryQuery(
    bidlClient.post(
      `${baseUri}/location/v1/GetLocationById`,
      {
        id: locationId,
        companyId: companyId,
      },
      {
        headers: {
          'X-Bushel-Tenant': tenantId,
        },
      }
    )
  )
}

export const CreateOrUpdateLocation = async (
  tenantId: string,
  params: CreateOrUpdateLocationRequest[]
) => {
  return tryQuery(
    bidlClient.post(
      `${baseUri}/location/v1/CreateOrUpdateLocations`,
      {
        locations: params,
      },
      {
        headers: {
          'X-Bushel-Tenant': tenantId,
        },
      }
    )
  )
}
