import { QueryObserverResult, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  CreateOrUpdateLocation,
  CreateOrUpdateLocationRequest,
  FindOrCreateTenantBySlug,
  GetConfigurables,
  GetLocationById,
  UpdateConfigurables,
} from 'src/api/microservices/aggregator'
import { AxiosError } from 'axios'

interface TenantConfigResponse {
  key: string
  label: string
  description: string
  isFeature: boolean
  group: string
  dataType: string
  options: string[]
}

interface TenantUpdateResponse {
  data: [
    {
      value: string
      key: string
    },
  ]
}

type GetLocationByIdResponse = {
  data: {
    data: TenantLocation
    type: string
  }
  type: string
}

export const useFindTenantBySlug = (slug: string): QueryObserverResult<Tenant[], unknown> =>
  useQuery({
    queryKey: ['aggregator-config-slug', slug],
    queryFn: async () => {
      let [err, data] = await FindOrCreateTenantBySlug(slug)
      if (err) throw err
      return data.data
    },
  })

export const useGetConfigurables = (
  slug: string
): QueryObserverResult<TenantConfigResponse[], unknown> =>
  useQuery({
    queryKey: ['aggregator-config', slug],
    queryFn: async () => {
      let [err, response] = await GetConfigurables(slug)
      if (err) throw err
      return response.data
    },
  })

export const useUpdateConfigurables = (slug: string) => {
  return useMutation({
    mutationFn: async (payload: { data: TenantUpdateResponse[] }) => {
      const [err, response] = await UpdateConfigurables(payload, slug)
      if (err) throw err
      return response
    },
  })
}

export const GET_LOCATION_BY_ID_QUERY_KEY = 'get-location-by-id'
export function useGetLocationById({ tenantId, locationId, companyId }) {
  return useQuery<GetLocationByIdResponse, AxiosError<MicroserviceError>>({
    queryKey: [GET_LOCATION_BY_ID_QUERY_KEY, { tenantId, locationId, companyId }],
    queryFn: async () => {
      const [err, response] = await GetLocationById({ tenantId, locationId, companyId })
      if (err) throw err
      return response
    },
    enabled: !!locationId,
  })
}

export function useCreateOrUpdateLocation({ tenantId, onSuccess, ...options }) {
  const queryClient = useQueryClient()
  let { mutateAsync: createOrUpdateLocation, ...rest } = useMutation({
    mutationFn: async (params: CreateOrUpdateLocationRequest[]) => {
      const [err, response] = await CreateOrUpdateLocation(tenantId, params)
      if (err) throw err
      return response
    },
    onSuccess: (data, variables, ctx) => {
      queryClient.invalidateQueries({ queryKey: [GET_LOCATION_BY_ID_QUERY_KEY] })
      onSuccess?.(data, variables, ctx)
    },
    ...options,
  })

  return { createOrUpdateLocation, ...rest }
}
