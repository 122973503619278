import React from 'react'
import { Switch, Tooltip, VStack } from '@chakra-ui/react'
import AddLocationParent from './location/add-location-parent'
import { LocationType } from 'src/companies/locations/v2/utils'
import { useLocationDetailContext } from 'src/companies/locations/v2/location-details-context'
import AddFacilityChildren from './facility/add-facility-children'
import { FormLabel } from 'src/ui/form-controls'

export default function FacilityOptions() {
  const { locationType, setLocationType, facilityChildren, parent } = useLocationDetailContext()
  const isFacility = locationType === LocationType.facility

  const tooltipLabel = isFacility
    ? 'Cannot change to location with assigned sub-locations'
    : 'Cannot change to facility with an assigned parent'

  const canToggleSwitch =
    (isFacility && (!facilityChildren || facilityChildren?.length === 0)) ||
    (!isFacility && !parent)

  return (
    <VStack px={10} spacing={4} alignItems="start">
      <FormLabel>Facility</FormLabel>
      <Tooltip hasArrow label={tooltipLabel} isDisabled={canToggleSwitch} shouldWrapChildren>
        <Switch
          isChecked={isFacility}
          isDisabled={!canToggleSwitch}
          onChange={(e) =>
            setLocationType(e.target.checked ? LocationType.facility : LocationType.location)
          }
        />
      </Tooltip>
      {isFacility ? <AddFacilityChildren /> : <AddLocationParent />}
    </VStack>
  )
}
