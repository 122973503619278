import { useQuery } from '@tanstack/react-query'
import { centreAPIs } from 'src/utils/api'

export const useLocations = ({ companySlug }) => {
  let { data, status, error, refetch, isFetching } = useQuery({
    queryKey: ['company-locations', { companySlug }],
    queryFn: async () => {
      let [err, response] = await centreAPIs.getCentreElevatorsByCompanySlug({
        companySlug,
      })

      if (err) throw err
      return response.data.data
    },
    gcTime: 0,
  })

  return {
    data,
    status,
    error,
    refetch,
    isFetching,
  }
}
