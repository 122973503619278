import React from 'react'
import { AuthRoute, FlexRoutes } from 'src/routes'
import { Route } from 'react-router-dom'
import LocationsList from './locations-list'
import LocationDetailsPage from './location-details'

export default function LocationsPage() {
  return (
    <FlexRoutes>
      <Route path="/:id" element={<AuthRoute as={LocationDetailsPage} />} />
      <Route path="/new" element={<AuthRoute as={LocationDetailsPage} />} />
      <Route path="*" element={<AuthRoute as={LocationsList} />} />
    </FlexRoutes>
  )
}
