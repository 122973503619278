import React from 'react'
import { Button, Flex, useDisclosure, VStack } from '@chakra-ui/react'
import { useLocationDetailContext } from 'src/companies/locations/v2/location-details-context'
import { LocationType } from 'src/companies/locations/v2/utils'
import { FormLabel } from 'src/ui/form-controls'
import InfiniteModal from 'src/companies/locations/v2/facility-management/infinite-modal'
import FacilityChildrenTable from './facility-children-table'

export default function AddFacilityChildren() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { setFacilityChildren } = useLocationDetailContext()

  function handleAddChildClicked(childToAdd: TenantLocation) {
    setFacilityChildren(((facilityChildren: TenantLocation[]) => [
      ...facilityChildren,
      childToAdd,
    ]) as any)
  }

  return (
    <>
      <VStack spacing={4} alignItems="start" width="100%">
        <Flex justifyContent="space-between" width="100%" alignItems="center">
          <FormLabel width="auto" mr="auto">
            Sub-Locations
          </FormLabel>
          <Button colorScheme="primary" maxW={240} onClick={onOpen}>
            Add Sub-Locations
          </Button>
        </Flex>
        <FacilityChildrenTable />
      </VStack>
      <InfiniteModal
        isOpen={isOpen}
        onClose={onClose}
        handleAddClicked={handleAddChildClicked}
        locationType={LocationType.facility}
      />
    </>
  )
}
