import { getLocationDisplayName } from 'src/companies/locations/v2/utils'
import { Button } from '@chakra-ui/react'
import { Table2 } from 'src/ui'
import React, { useMemo } from 'react'
import { useLocationDetailContext } from 'src/companies/locations/v2/location-details-context'

export default function FacilityChildrenTable() {
  const { facilityChildren, setFacilityChildren } = useLocationDetailContext()

  const facilityChildrenMemo = useMemo(
    () =>
      facilityChildren?.map((child) => (
        <tr key={child.id}>
          <td>{getLocationDisplayName(child)}</td>
          <td>{child.sourceId}</td>
          <td align="right">
            <Button
              variant="ghost"
              color="red.500"
              fontSize="sm"
              onClick={() =>
                setFacilityChildren(facilityChildren.filter((current) => current.id !== child.id))
              }
            >
              Remove
            </Button>
          </td>
        </tr>
      )),
    [facilityChildren, setFacilityChildren]
  )

  return (
    <Table2 justifyContent="start">
      <thead>
        <tr>
          <th>Name</th>
          <th>Remote ID</th>
        </tr>
      </thead>
      <tbody>{facilityChildrenMemo}</tbody>
    </Table2>
  )
}
