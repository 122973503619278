import {
  Input,
  InputGroup,
  InputGroupProps,
  InputLeftElement,
  InputRightElement,
  Spinner,
} from '@chakra-ui/react'
import { SearchIcon } from './index'
import { SmallCloseIcon } from '@chakra-ui/icons'
import React from 'react'

type SearchInputProps = Partial<InputGroupProps> & {
  searchValue: string
  setSearchValue: (value: string) => void
  isLoading: boolean
}

export default function SearchInput({
  searchValue,
  setSearchValue,
  isLoading,
  ...props
}: SearchInputProps) {
  return (
    <InputGroup size="lg" alignItems="center" {...props}>
      <InputLeftElement height="40px" width="40px">
        {isLoading ? <Spinner size="sm" /> : <SearchIcon color="gray.500" fontSize="sm" />}
      </InputLeftElement>
      <Input
        autoFocus
        bgColor="white"
        value={searchValue}
        placeholder="Search..."
        data-testid="search-input"
        _placeholder={{ color: 'gray.500' }}
        height="40px"
        fontSize="sm"
        onChange={(e) => setSearchValue(e.currentTarget.value)}
      />
      {!!searchValue?.length && (
        <InputRightElement p={2} height="40px" width="40px">
          <SmallCloseIcon onClick={() => setSearchValue('')} cursor="pointer" />
        </InputRightElement>
      )}
    </InputGroup>
  )
}
