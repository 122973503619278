export const areAnyLoading = (...queries) =>
  queries.some(({ status, fetchStatus }) => status === 'pending' || fetchStatus === 'fetching')
export const areAnyFailed = (...queries) => queries.some(({ error }) => !!error)
export const areAllLoaded = (...queries) =>
  queries.every(({ status, error, data }) => status === 'success' && !error && !!data)

export const tryQuery = async (query: Promise<any>) => {
  try {
    const response = await query
    return [null, response.data]
  } catch (error) {
    return [error, null]
  }
}
